<template>
	<div class="home" style="height: 100vh;">
		<van-nav-bar class="van-nav-bar--fixed" title="彩图" @click-left="goToUrl('/picture')">
			<template #left>
				<van-icon name="search" size="16" />
			</template>

			<template #right>
				<img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAARCAYAAAFMandsAAAAAXNSR0IArs4c6QAAAmFJREFUOBGNVE1oE1EQnvd2m1AFLUIRTBt79IceLF6ySaQoVE/ZpBDEVFGyqwehJ3vwIG0vHlTUSC5SahQ8hUKtuXisKE292CKCiiKITfy79KBJ3JB947ytuyS6/swhM2++7828973ZALSblsyOtK8746huHu3MRBLGJy+jjRo7tZR5UCa4m8UWvEBbZNy15zXdqHqsSCKLhNx1UEJuuzSHEQpsORvTT6cQkTEXOXTM3B7g3Q0v4QKup7PVgkP9W5vPKgccUkTP1gGhmzH2gSHmlkqFKy5ZelX+ENMCztaEwJUnvxAk7msR3ViVAPlF6f94JrraK0S47F1csl2LJc3hUODwXrpj7rcKpFCR6m4jaVc5ihyL6UbGBsgT8zUJs6cvGO6dm5tuutVA07PrcqEljA1dPWQjYI7OCKoAHOtS1dDj+ZmP7ZyOM0R1Y5LanCRtpgFQKS8U7rSTvTiWzF6llhdkwlHUQ34GtmADFPbLZUcLmfibxdJnwnaz9YYBmwSGdRDsGnBll28Xv0I0v3Hbat2gAWjSuB1hyGwE/Bzs2VH1isi5atRwv8qU94/uzzwfOTGx+dvX9fMgcAKBVQDFueVSYcivAYunx3tbVv0dgePLpVuFiH5qkMRaoSctA+9Kl+/d/OK3sT3HhVWjZ8ZNiqI+lUBw38BLKvAWGFv8nwJyjyOs/F6iKfM4DX2cvpw1RbCqADEl54iucrEn3H39QT5vyQ1+9s/XGR41+ywbLwFihiF8pyZFOnmKcz61tDCbk0U9Yf06yNzD+dkKuTGa8Ab9b+zmjBeFsAcFYtjd8wO2DNQkSsFZNAAAAABJRU5ErkJggg=="
					width="13" />
			</template>
		</van-nav-bar>
		<van-pull-refresh v-model="loading" @refresh="onRefresh">
			
				<div class="banner">
					<van-swipe class="my-swipe" :autoplay="5000">
						<van-swipe-item v-for="item in slide_list" :key="item.id">
							<div class="item" @click="goLocation(item.link)">
								<img :src="siteurl + item.smallimage" />
							</div>
						</van-swipe-item>
					</van-swipe>
				</div>
				<van-notice-bar left-icon="volume-o" :text="notice" />

				<div class="block-lottery">
					<ul class="platform-list">
						<li v-for="item in lotto_list" :key="item.id">
							<div :class="item.id == lotto_id ? 'item-body act' : 'item-body'"
								@click="setLotto(item.id)">
								<div class="title">
									<b>{{ item.name }}</b>
								</div>
								<div class="time">
									<span>{{ item.time ? item.time : "0月0日" }}开奖</span>
								</div>
							</div>
						</li>
					</ul>
					<div class="border-bg"></div>
					<div class="border-box" v-for="item in lotto_list" :key="item.id" style="padding: 0 0">
						<KaiJiangShow :key="item.time" :lotto_id="item.id"
							:style="item.id == lotto_id ? 'display: block;' : 'display: none;'" />
					</div>
				</div>

				<div class="category-tr">
					<div class="category-icon van-row">
						<div class="van-col van-col--6">
							<div class="icon-text" @click="goToUrl('/video?lotto_id=' + 1)">
								<div>
									<div class="icon-bg">
										<img src="img/kaijiangxianchang.06c915b5.png" />
									</div>
								</div>
								<div class="text">澳彩开奖视频</div>
							</div>
						</div>

						<div class="van-col van-col--6">
							<div class="icon-text" @click="goToUrl('/video?lotto_id=' + 2)">
								<div>
									<div class="icon-bg" style="background-image: linear-gradient(0deg,rgb(36, 171, 200) 0%,rgb(76, 145, 244) 100%); ">
										<img src="img/ziliaodaquan.2b434b4d.png" />
									</div>
								</div>
								<div class="text">港彩开奖视频</div>
							</div>
						</div>
						<div class="van-col van-col--6">
							<div class="icon-text" @click="goToUrl('/video?lotto_id=' + 3)">
								<div>
									<div class="icon-bg">
										<img src="img/zixuntongji.876c0a58.png" />
									</div>
								</div>
								<div class="text">新港开奖视频</div>
							</div>
						</div>
						<div class="van-col van-col--6">
							<div class="icon-text" @click="goToUrl('/shengxiao')">
								<div>
									<div class="icon-bg">
										<img src="img/chaxunzhushou.fc4826b9.png" />
									</div>
								</div>
								<div class="text">生肖资料图</div>
							</div>
						</div>
						<!-- <div class="van-col van-col--6">
          <div class="icon-text" @click="goToUrl('/vote?lotto_id=' + lotto_id)">
            <div>
              <div class="icon-bg"><img src="img/ymjc.2895ae7d.png" /></div>
            </div>
            <div class="text">幽默猜测</div>
          </div>
        </div>
        <div class="van-col van-col--6">
          <div
            class="icon-text"
            @click="goToUrl('/picture?lotto_id=' + lotto_id)"
          >
            <div>
              <div class="icon-bg">
                <img src="img/liuhetuku.14ad3160.png" />
              </div>
            </div>
            <div class="text">六合图库</div>
          </div>
        </div>

        <div class="van-col van-col--6">
          <div class="icon-text">
            <div>
              <div class="icon-bg">
                <img src="img/jiaoliudating.18841653.png" />
              </div>
            </div>
            <div class="text">高手论坛</div>
          </div>
        </div>
        <div class="van-col van-col--6">
          <div class="icon-text">
            <div>
              <div class="icon-bg">
                <img src="img/gongjubaoxiang.ab44efbf.png" />
              </div>
            </div>
            <div class="text">工具宝箱</div>
          </div>
        </div> -->
					</div>
				</div>

				<div class="body-image-bd" style="margin-top: 20px">
					<van-list class="waterfall" @load="onLoad" :finished="finished">
						<div v-for="item in list" :key="item.id" class="image-item"
							@click="goToUrl('/picture/show?information_id=' + item.information_id +'&journal_id=' + item.journal_id +'&lotto_id=' +lotto_id)">
							<div class="image-item-image">
								<img y="311" style="width: 100%"
									:src="  item.smallimage.length > 0  ? siteurl + item.smallimage     : '/img/no_image.png' " />
							</div>
							<div class="image-item-title">
								{{ item.title }}
							</div>
						</div>
					</van-list>
				</div>
			
		</van-pull-refresh>
	</div>
</template>

<script>
	import KaiJiangShow from "@/components/KaiJiangShow.vue";
	import {
		Toast
	} from "vant";
	import {
		Dialog
	} from "vant";

	export default {
		components: {
			KaiJiangShow,
		},
		data() {
			return {
				year_id: 1,
				lotto_id: 2,
				active: "index",
				list: [],
				slide_list: [],
				lotto_list: [],
				notice: "",
				loading: false,
				finished: false,
				iserror: false,
				refreshing: false,
				page: 1,
				message: "",
			};
		},
		created() {
			//1分钟刷新一次数据
			/*
    setInterval(() => {
        this.runTime();
    }, 3000);
   */
		},
		mounted() {
			this.active = "index";
			this.getIndexData();
			this.getPictureData();
		},
		methods: {
			/*
			    runTime(){
			        var date = new Date();
			  
			        var end_time=date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+" 21:30:00";
			        var go_time=date.getFullYear()+'-'+(date.getMonth()+1)+'-'+date.getDate()+" 21:35:00";

			        var date_go = new Date(go_time);
			        var go= date_go.getTime();

			        var date_end = new Date(end_time);
			        var end= date_end.getTime();
			        var now = new Date();
			        var now_time = now.getTime();

			        if(now_time>end && now_time<go){
			            this.getIndexData();      
			        }
			    },
			    */
			goToUrl(url) {
				this.$router.push({
					path: url
				});
			},
			goLocation(url) {
				if (url) {
					location.href = url;
				}
			},
			setLotto(id) {
				this.finished = true;
				this.page = 1;
				this.list = [];
				this.lotto_id = id;
				this.getPictureData();
			},
			getIndexData() {
				this.$http.get("api/index/index").then(function(response) {
					if (response.data.code == "ok") {
						this.slide_list = response.data.data.slide_list;
						this.notice = response.data.data.notice;
						this.lotto_list = response.data.data.lotto_list;

						if (!window.localStorage.getItem("isNotice")) {
							if (this.notice.length > 0) {
								Dialog.confirm({
										title: "系统公告",
										message: this.notice,
										confirmButtonText: "不再提醒",
									})
									.then(() => {
										// on confirm
										window.localStorage.setItem("isNotice", true);
									})
									.catch(() => {
										// on cancel
									});
							}
						}
					} else {
						this.iserror = true;
						this.message = response.data.msg;
					}
					this.loading = false;
					//console.log(this.list);
				});
			},
			onRefresh(e) {
				console.log('handleScroll', e)
				this.page == 1
				this.getIndexData();
				this.getPictureData();
			},
			onLoad() {
				this.page++;
				this.getPictureData();
			},
			getPictureData() {
				this.finished = true;
				Toast.loading({
					message: "加载中...",
					forbidClick: true,
				});
				this.$http
					.get(
						"api/picture/index?page=" +
						this.page +
						"&year_id=" +
						this.year_id +
						"&lotto_id=" +
						this.lotto_id
					)
					.then(function(response) {
						if (response.data.code == "ok") {
							const data = response.data.data.list.data;
							if (data.data.length < 10) {
								this.finished = true;
							} else {
								this.finished = false;
							}

							if (!data.data.length) {
								this.finished = true;
							} else {
								for (let i = 0; i < data.data.length; i++) {
									this.list.push(data.data[i]);
								}
							}

							this.loading = false;
							// 数据全部加载完成
							if (this.list.length >= data.total) {
								this.finished = true;
							}
							//this.finished = true;
						} else {
							this.iserror = true;
							this.message = response.data.msg;
						}
						this.loading = false;
						Toast.clear();
					});
			}
		},
	};
</script>

<style>
	.van-dialog {
		width: auto;
	}

	.van-dialog__header {
		padding-top: 12px;
		padding-bottom: 12px;
		font-weight: 100;
		font-size: 20px;
		color: #fff;
		line-height: 24px;
		text-align: center;
		background-color: #07c160;
	}

	.van-dialog__message {
		font-size: 16px;
	}

	.home {
		background-color: #ffffff;
	}

	.van-notice-bar {
		background-color: #ffffff;
	}

	.van-col_list {
		float: left;
		box-sizing: border-box;
		min-height: 1px;
		margin: 0;
		padding: 0;
	}

	.waterfall {
		display: flex;
		width: 100%;
		flex-wrap: wrap;
		box-sizing: border-box;
		padding: 0 8px;
	}

	.waterfall .image-item {
		margin-bottom: 0.2rem;
		box-shadow: 0 0.02rem 0.08rem 0 hsla(0, 0%, 62.7%, 0.5);
		border-radius: 4px;
		border: 1px solid #ededed;
		position: relative;
		padding: 1px;
		width: calc(50% - 16px);
		margin: 8px;
		text-align: center;
	}

	.image-item-title {
		padding: 4px;
	}

	.van-cell__value--alone {
		color: #323233;
		text-align: center;
		line-height: 30px;
		font-size: 16px;
	}

	.home .block-lottery .platform-list li {
		width: 33.33%;
	}

	.home .block-lottery .platform-list li .item-body .time span {
		float: none;
	}
</style>